(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var isTouchDevice = exports.isTouchDevice = function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints;
};

},{}],2:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _featureDetection = require('./featureDetection');

var rand = function rand(min, max) {
	return min + (max - min) * Math.random();
};

var clamp = function clamp(min, max) {
	return function (x) {
		return Math.max(min, Math.min(x, max));
	};
};

var arrClone = function arrClone(arr) {
	var res = [];

	for (var i = 0; i < arr.length; i++) {
		if (Array.isArray(arr[i])) {
			res[i] = arrClone(arr[i]);
		} else {
			res[i] = arr[i];
		}
	}

	return res;
};

var container = void 0,
    canvas = void 0,
    ctx = void 0,
    w = void 0,
    h = void 0,
    ratio = void 0,
    graphHeight = void 0,
    graphBottom = void 0,
    fadeout = void 0,
    oldLines = void 0,
    newLines = void 0,
    lastUpdate = void 0;

var minPart = 0.1;
var maxPart = 0.3;
var updateDuration = 2000; //ms
var updateInterval = 4500; //ms
var lineCount = 3;
var stepCount = 4;

var resize = function resize() {
	ratio = window.devicePixelRatio || 1;
	w = window.innerWidth * ratio;
	h = container.offsetHeight * ratio;

	canvas.width = w;
	canvas.height = h;
};

var scroll = function scroll() {
	var scrolled = Math.min(window.scrollY / container.offsetHeight, 1);

	graphBottom = 1 - (1 - minPart) * scrolled;
	graphHeight = minPart * scrolled + maxPart * (1 - scrolled);
};

var draw = function draw() {
	scroll();

	ctx.clearRect(0, 0, w, h);
	ctx.fillStyle = '#050047';
	ctx.fillRect(0, 0, w, h * graphBottom);

	getLines().forEach(function (line) {
		ctx.beginPath();
		ctx.moveTo(0, h * (graphBottom - graphHeight * line[0]));

		for (var i = 1, l = line.length - 1; i <= l; i++) {
			ctx.lineTo(i * w / l, h * (graphBottom - graphHeight * line[i]));
		}

		ctx.strokeStyle = '#fff';
		ctx.lineWidth = 2 * ratio;
		ctx.stroke();

		ctx.lineTo(w, h * graphBottom);
		ctx.lineTo(0, h * graphBottom);
		ctx.closePath();

		ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
		ctx.fill();
	});

	fadeout = ctx.createLinearGradient(0, h * graphBottom - 1, 0, h * (graphBottom + 0.1));
	fadeout.addColorStop(0, 'rgba(224, 223, 232, 1)');
	fadeout.addColorStop(1, 'rgba(224, 223, 232, 0)');

	ctx.fillStyle = fadeout;
	ctx.fillRect(0, h * graphBottom - 1, w, h * 0.1 + 1);

	requestAnimationFrame(draw);
};

var getLines = function getLines() {
	var p = clamp(0, 1)((Date.now() - lastUpdate) / updateDuration);
	p = (1 - Math.cos(Math.PI * p)) / 2;

	var lines = [];

	for (var i = 0; i < oldLines.length; i++) {
		lines[i] = [];
		for (var j = 0; j < oldLines[i].length; j++) {
			lines[i][j] = p * newLines[i][j] + (1 - p) * oldLines[i][j];
		}
	}

	return lines;
};

var generateTrend = function generateTrend(steps) {
	return function () {
		var points = Math.pow(2, steps);
		var res = new Array(points + 1);

		res[0] = rand(0, 1);
		res[res.length - 1] = rand(0, 1);

		var size = points;

		while ((size = size / 2) >= 1) {
			for (var i = size; i < points; i += 2 * size) {
				res[i] = (res[i - size] + res[i + size]) / 2; // Average
				res[i] += rand(-1, 1) * size / points; // Add random part
				res[i] = Math.max(0, res[i]);
			}
		}

		return res;
	};
};

var generateTrends = function generateTrends(n, steps) {
	return new Array(n).fill(null).map(generateTrend(steps));
};

var updateLines = function updateLines() {
	oldLines = arrClone(newLines);
	newLines = generateTrends(lineCount, stepCount);
	lastUpdate = Date.now();

	setTimeout(updateLines, updateInterval);
};

var graphCanvas = function graphCanvas(containerEl, canvasEl) {
	container = containerEl;
	canvas = canvasEl;
	ctx = canvas.getContext('2d');

	resize();

	newLines = new Array(lineCount).fill(new Array(Math.pow(2, stepCount) + 1).fill(0));
	updateLines();

	window.addEventListener('resize', resize);

	requestAnimationFrame(draw);
};

exports.default = graphCanvas;

},{"./featureDetection":1}],3:[function(require,module,exports){
'use strict';

var _featureDetection = require('./featureDetection');

var _graphCanvas = require('./graphCanvas');

var _graphCanvas2 = _interopRequireDefault(_graphCanvas);

var _schedule = require('./schedule');

var _schedule2 = _interopRequireDefault(_schedule);

var _now = require('./now');

var _now2 = _interopRequireDefault(_now);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var init = function init() {
	if ((0, _featureDetection.isTouchDevice)()) {
		document.body.classList.add('touch');
	}

	(0, _now2.default)();

	var header = document.querySelector('header');
	var canvas = document.querySelector('#backgroundCanvas');
	var scheduleContainer = document.querySelector('#scheduleContainer');

	if (header && canvas) {
		(0, _graphCanvas2.default)(header, canvas);
	}

	if (scheduleContainer) {
		(0, _schedule2.default)(scheduleContainer);
	}
};

window.addEventListener('load', init);

},{"./featureDetection":1,"./graphCanvas":2,"./now":4,"./schedule":5}],4:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _schedule = require('../schedule.json');

var _schedule2 = _interopRequireDefault(_schedule);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SECOND = 1000;
var MINUTE = SECOND * 60;
var HOUR = MINUTE * 60;
var DAY = HOUR * 24;

var startDate = new Date("2017-05-13T00:00:00+02:00");

var formattedDates = void 0;

// Elements
var hackTimer = document.querySelector('#hackTimer .timer');
var nextState = document.querySelector('.next-state');

var eventTimer = document.querySelector('#eventTimer .timer');
var event = document.querySelector('.event');

var formatTime = function formatTime(td, l, m) {
	var n = '' + Math.floor(td / l % m);
	return ('00' + n).substring(n.length);
};

var formatDate = function formatDate(td) {
	return formatTime(td, HOUR, 1000) + ':' + formatTime(td, MINUTE, 60) + ':' + formatTime(td, SECOND, 60);
};

var updateHacking = function updateHacking(currentDate) {
	var hacking = {
		start: new Date("2017-05-13T16:00:00+02:00"),
		end: new Date("2017-05-14T14:30:00+02:00")
	};

	var started = currentDate >= hacking.start;

	nextState.innerText = started ? 'ends' : 'starts';

	if (started) {
		hackTimer.innerText = formatDate(hacking.end - currentDate);
	} else {
		hackTimer.innerText = formatDate(hacking.start - currentDate);
	}
};

var first = function first(xs) {
	return xs.length ? xs[0] : null;
};

var last = function last(xs) {
	return xs.length ? xs[xs.length - 1] : null;
};

var updateEvents = function updateEvents(currentDate) {
	var lastEvent = last(formattedDates.filter(function (date) {
		return date.start < currentDate;
	}));
	var nextEvent = first(formattedDates.filter(function (date) {
		return date.start > currentDate;
	}));

	var busy = lastEvent != null && currentDate >= lastEvent.start && currentDate - lastEvent.start < HOUR;

	if (busy) {
		eventTimer.innerText = "Now";
		event.innerText = lastEvent.description;
	} else if (nextEvent == null) {
		eventTimer.innerText = "Now";
		event.innerText = "HackDelft is over...";
	} else {
		eventTimer.innerText = formatDate(nextEvent.start - currentDate);
		event.innerText = nextEvent.description + ' in';
	}
};

var update = function update() {
	var currentDate = Date.now();

	updateHacking(currentDate);
	updateEvents(currentDate);
};

var convertDate = function convertDate(date) {
	return Object.assign(date, {
		start: new Date(date.start)
	});
};

var now = function now() {
	if (hackTimer != null) {
		formattedDates = _schedule2.default.map(convertDate);

		update();
		setInterval(update, 1000);
	}
};

exports.default = now;

},{"../schedule.json":6}],5:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _schedule = require('../schedule.json');

var _schedule2 = _interopRequireDefault(_schedule);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DAY = 1000 * 60 * 60 * 24;

var startDate = new Date("2017-05-13T00:00:00+02:00");

var convertDate = function convertDate(date) {
	return Object.assign(date, {
		start: new Date(date.start),
		end: date.end ? new Date(date.end) : null
	});
};

var only2 = function only2(n) {
	return ('0' + n).slice(-2);
};

var parseTime = function parseTime(date) {
	return only2(date.getHours()) + ':' + only2(date.getMinutes());
};

var insertDate = function insertDate(days) {
	return function (item) {
		var day = Math.floor((item.start - startDate) / DAY) * DAY;

		var el = document.createElement('div');
		el.className = 'date';
		el.classList.toggle('duration', item.end);
		el.classList.toggle('important', item.description.includes('Hacking'));
		el.dataset.start = parseTime(item.start);
		el.dataset.end = item.end ? parseTime(item.end) : '';
		el.innerText = item.description;

		days[day / DAY].appendChild(el);
	};
};

var schedule = function schedule(root) {
	var days = root.querySelectorAll('.day');

	_schedule2.default.map(convertDate).forEach(insertDate(days));
};

exports.default = schedule;

},{"../schedule.json":6}],6:[function(require,module,exports){
module.exports=[
	{
		"start": "2017-05-13T12:00:00.000+02:00",
		"description": "Doors open for teams"
	},
	{
		"start": "2017-05-13T13:00:00.000+02:00",
		"end":   "2017-05-13T14:00:00.000+02:00",
		"description": "Opening ceremony"
	},
	{
		"start": "2017-05-13T14:00:00.000+02:00",
		"end":   "2017-05-13T14:20:00.000+02:00",
		"description": "Workshop round 1: Partners"
	},
	{
		"start": "2017-05-13T14:25:00.000+02:00",
		"end":   "2017-05-13T14:45:00.000+02:00",
		"description": "Workshop round 2: Partners"
	},
	{
		"start": "2017-05-13T14:50:00.000+02:00",
		"end":   "2017-05-13T15:40:00.000+02:00",
		"description": "Workshop round 3: Pitch training"
	},
	{
		"start": "2017-05-13T15:40:00.000+02:00",
		"end":   "2017-05-13T16:00:00.000+02:00",
		"description": "Workshop round 4: Group formation"
	},
	{
		"start": "2017-05-13T16:00:00.000+02:00",
		"description": "Hacking starts!"
	},
	{
		"start": "2017-05-13T18:00:00.000+02:00",
		"description": "Dinner served"
	},
	{
		"start": "2017-05-13T22:00:00.000+02:00",
		"description": "Late night snack #1"
	},
	{
		"start": "2017-05-14T02:00:00.000+02:00",
		"description": "Late night snack #2"
	},
	{
		"start": "2017-05-14T08:00:00.000+02:00",
		"description": "Breakfast served"
	},
	{
		"start": "2017-05-14T12:00:00.000+02:00",
		"description": "Lunch served"
	},
	{
		"start": "2017-05-14T14:30:00.000+02:00",
		"description": "Hacking ends"
	},
	{
		"start": "2017-05-14T14:30:00.000+02:00",
		"end": "2017-05-14T16:00:00.000+02:00",
		"description": "Pitches for the judges"
	},
	{
		"start": "2017-05-14T16:30:00.000+02:00",
		"description": "Judges announce finalists (7 groups)"
	},
	{
		"start": "2017-05-14T16:45:00.000+02:00",
		"end": "2017-05-14T17:30:00.000+02:00",
		"description": "Finalists pitches"
	},
	{
		"start": "2017-05-14T17:45:00.000+02:00",
		"end": "2017-05-14T18:00:00.000+02:00",
		"description": "Winner announcement and prizes!"
	},
	{
		"start": "2017-05-14T18:00:00.000+02:00",
		"end": "2017-05-14T19:00:00.000+02:00",
		"description": "Network drinks"
	}
]

},{}]},{},[3]);
